import React from 'react'
import Helmet from 'react-helmet'

export default ({ meta }) => (
  <Helmet>
    <meta name='slack-app-id' content={process.env.GATSBY_SLACK_APP_ID} />
    <title>{meta.title}</title>
    <meta name='description' content={meta.description} />
    <meta property='og:title' content={meta.og_title} />
    <meta property='og:type' content='website' />
    <meta property='og:image' content={meta.og_image} />
    <meta property='og:image:width' content={meta.og_image_width} />
    <meta property='og:image:height' content={meta.og_image_height} />
    <meta property='og:url' content={meta.og_url} />
    <meta property='og:description' content={meta.og_description} />
  </Helmet>
)
