import React from 'react'
import { Location } from '@reach/router'

const withLocation = BaseComponent => props => (
  <Location>
    {({ location, navigate }) => (
      <BaseComponent {...props} location={location} navigate={navigate} />
    )}
  </Location>
)

export default withLocation
