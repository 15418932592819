import i18n from 'src/providers/i18n'
import { pipe, propOr, ifElse, prop, always } from 'ramda'
import { isNotNilOrEmpty } from 'src/utils/ramda'
import { AVATARS } from 'src/utils/avatar'

export const USER_ROLES = {
  admin: 'admin',
  user: 'user'
}

export const userRoleOptions = () => [
  {
    label: i18n.t(`userRoles.${USER_ROLES.admin}`),
    value: USER_ROLES.admin
  },
  {
    label: i18n.t(`userRoles.${USER_ROLES.user}`),
    value: USER_ROLES.user
  }
]

const hasAvatarUrlAttached = pipe(propOr(null, 'avatarUrl'), isNotNilOrEmpty)
const hasAvatarAttached = pipe(propOr(null, 'avatar'), isNotNilOrEmpty)
const getAvatarImage = avatarName => AVATARS[avatarName]

export const getUserPhotoOrAvatar = pipe(
  ifElse(
    hasAvatarUrlAttached,
    prop('avatarUrl'),
    ifElse(
      hasAvatarAttached,
      pipe(prop('avatar'), getAvatarImage),
      pipe(always('avatar_3'), getAvatarImage)
    )
  )
)
