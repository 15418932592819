import { API_STATES } from 'src/utils/api'
import { propEq, propOr, pipe, pathOr, and } from 'ramda'
import { createSelector } from 'reselect'
import LocalStorageService from 'src/services/LocalStorageService'
import { KEYS } from 'src/utils/localStorage'
import { isNotNilOrEmpty } from 'src/utils/ramda'
import { USER_ROLES } from 'src/utils/user'

export const selectAuth = state => state.auth

export const isAuthLoading = createSelector(
  selectAuth,
  propEq('state', API_STATES.IN_PROGRESS)
)

export const isAuthLoaded = createSelector(
  selectAuth,
  propEq('state', API_STATES.DONE)
)

export const getIsAuthorised = () =>
  isNotNilOrEmpty(LocalStorageService.get(KEYS.token))

export const getRegistrationEmail = createSelector(
  selectAuth,
  propOr('', 'registrationEmail')
)

export const getOrganisationInvite = createSelector(
  selectAuth,
  propOr('', 'organisationInvite')
)

export const getAuthUser = createSelector(selectAuth, propOr({}, 'user'))

export const getIsAdminUser = createSelector(
  selectAuth,
  pipe(propOr({}, 'user'), propEq('role', USER_ROLES.admin))
)

export const getPointsLeft = createSelector(
  selectAuth,
  pathOr(0, ['user', 'pointsLeft'])
)

export const getFirstName = createSelector(
  selectAuth,
  pathOr('', ['user', 'firstName'])
)

export const getLastName = createSelector(
  selectAuth,
  pathOr('', ['user', 'lastName'])
)

export const getJobFunction = createSelector(
  selectAuth,
  pathOr('', ['user', 'jobFunction'])
)

export const getAppreciatedByNumber = createSelector(
  selectAuth,
  pathOr('', ['pointsSummary', 'appreciatedBy'])
)

export const getPointsReceivedNumber = createSelector(
  selectAuth,
  pipe(pathOr('', ['pointsSummary', 'pointsReceived']), Number)
)

export const getAppreciationsGivenNumber = createSelector(
  selectAuth,
  pipe(pathOr('', ['pointsSummary', 'appreciationsGiven']), Number)
)

export const getIsAuthUserFetched = createSelector(
  selectAuth,
  pipe(pathOr({}, ['user', 'id']), isNotNilOrEmpty)
)

export const getIsAuthUserActive = createSelector(
  selectAuth,
  pathOr(false, ['user', 'isActive'])
)

export const hasUserFirstNameAnaLastNameSaved = createSelector(
  selectAuth,
  and(
    pipe(
      pathOr('', ['user', 'firstName']),
      isNotNilOrEmpty
    ),
    pipe(
      pathOr('', ['user', 'lastName']),
      isNotNilOrEmpty
    )
  )
)
