import styled from 'styled-components'

export default styled.div`
  .web {
    display: block;

    ${props => props.theme.breakpointsMedia.mobile} {
      display: none;
    }
  }
  
  .web-inline {
    display: inline-block;

    ${props => props.theme.breakpointsMedia.mobile} {
      display: none;
    }
  }
  
  .large-desktop {
    display: block;

    ${props => props.theme.breakpointsMedia.smallDesktop} {
      display: none;
    }
    
    ${props => props.theme.breakpointsMedia.mobile} {
      display: none;
    }
  }
  
  .large-desktop-inline {
    display: inline-block;

    ${props => props.theme.breakpointsMedia.smallDesktop} {
      display: none;
    }
    
    ${props => props.theme.breakpointsMedia.mobile} {
      display: none;
    }
  }
  
  .small-desktop {
    display: none;

    ${props => props.theme.breakpointsMedia.smallDesktop} {
      display: block;
    }
  }
  
  .small-desktop-inline {
    display: none;

    ${props => props.theme.breakpointsMedia.smallDesktop} {
      display: inline-block;
    }
  }
  
  .mobile {
    display: none;

    ${props => props.theme.breakpointsMedia.mobile} {
      display: block;
    }
  }
  
  .mobile-inline {
    display: none;

    ${props => props.theme.breakpointsMedia.mobile} {
      display: inline-block;
    }
  }
`
