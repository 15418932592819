import avatar1 from 'src/assets/images/pictures/avatar_1.png'
import avatar2 from 'src/assets/images/pictures/avatar_2.png'
import avatar3 from 'src/assets/images/pictures/avatar_3.png'
import avatar4 from 'src/assets/images/pictures/avatar_4.png'
import avatar5 from 'src/assets/images/pictures/avatar_5.png'
import avatar6 from 'src/assets/images/pictures/avatar_6.png'

export const AVATARS = {
  avatar_1: avatar1,
  avatar_2: avatar2,
  avatar_3: avatar3,
  avatar_4: avatar4,
  avatar_5: avatar5,
  avatar_6: avatar6
}
