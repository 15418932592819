import { useState, useEffect } from 'react'
import { addEventListener, removeEventListener, windowElement } from 'src/utils/window';

export default (offset) => {
  const [lastScrollTop, setLastScrollTop] = useState(0)

  const onScroll = () => setLastScrollTop(windowElement.scrollY)
  const handleScroll = () => setTimeout(onScroll, 100)

  useEffect(() => {
    addEventListener('scroll', handleScroll)

    return () => {
      removeEventListener('scroll', handleScroll)
    }
  }, [])

  return lastScrollTop > offset
}
