import React, { useEffect } from 'react'
import styled from 'styled-components'
import scrollTo from 'gatsby-plugin-smoothscroll'
import PATHS, { LANDING_PAGE_SECTIONS } from 'src/utils/paths'
import { useTranslation } from 'react-i18next'
import withLocation from 'src/hoc/withLocation'
import { isNotNilOrEmpty } from 'src/utils/ramda'
import LogoDark from 'src/assets/images/vectors/panda-logo-dark.svg'
import LogoLight from 'src/assets/images/vectors/panda-logo-light.svg'

import { NavigationMobile, TopNavListItem } from 'de-suite-me-ui'
import LanguagePicker from 'src/features/locales/LanguagePicker'
// import DemoButton from 'src/features/landingPage/components/DemoButton'
import useScrollDetector from 'src/hooks/useScrollDetector'
// import SignUpButton from 'src/features/landingPage/components/SignupButton'

const NonAuthNavigationMobile = props => {
  const hasScrolled = useScrollDetector(20)
  const {
    location: { pathname, hash },
    navigate
  } = props
  const { t } = useTranslation()

  useEffect(() => {
    if (isNotNilOrEmpty(hash)) {
      setTimeout(() => scrollTo(hash), 500)
    }
  }, [hash])

  const scrollToSection = id => () => navigate(`/#${id}`)

  const isCurrentPath = path => pathname === path
  const navigateToPath = path => () => navigate(path)
  const isHomepage = pathname === PATHS.homepage
  const primaryState = isHomepage && !hasScrolled

  return (
    <StyledNavigationContainer primaryState={primaryState}>
      <NavigationMobile
        onLogoClick={navigateToPath(PATHS.homepage)}
        drawerBottomElements={<LanguagePicker />}
        logo={primaryState ? LogoLight : LogoDark}
        logoDrawer={LogoDark}
      >
        <TopNavListItem
          className='top-nav__list-item'
          onClick={scrollToSection(LANDING_PAGE_SECTIONS.howItWorksSection)}
        >
          {t('navigation.howItWorks')}
        </TopNavListItem>
        <TopNavListItem
          className='top-nav__list-item'
          onClick={scrollToSection(LANDING_PAGE_SECTIONS.aboutSection)}
        >
          {t('navigation.about')}
        </TopNavListItem>
        <TopNavListItem
          className='top-nav__list-item'
          onClick={scrollToSection(LANDING_PAGE_SECTIONS.contactSection)}
        >
          {t('navigation.contact')}
        </TopNavListItem>
        <TopNavListItem
          className='top-nav__list-item'
          isActive={isCurrentPath(PATHS.logIn)}
          onClick={navigateToPath(PATHS.logIn)}
        >
          {t('navigation.login')}
        </TopNavListItem>
        <DemoButtonContainer>
          {/* <SignUpButton className='top-nav-mobile__demo-button' /> */}
          {/*  <DemoButton className='top-nav-mobile__demo-button' /> */}
        </DemoButtonContainer>
      </NavigationMobile>
    </StyledNavigationContainer>
  )
}

export default withLocation(NonAuthNavigationMobile)

const DemoButtonContainer = styled.div`
  margin-top: 10px;

  .top-nav-mobile__demo-button {
    background-color: ${props => props.theme.palette.common.flamingo};
    color: ${props => props.theme.palette.primary.contrastText};
  }
`

const StyledNavigationContainer = styled.div`
  & > div:first-of-type {
    background-color: ${props =>
      props.primaryState ? 'transparent' : props.theme.palette.common.white};
    box-shadow: ${props =>
      props.primaryState ? 'none' : props.theme.shadows.main};
    transition: ${props =>
      `all ${props.theme.transitions.duration.standard}ms ${props.theme.transitions.easing.easeInOut}`};
  }
`
