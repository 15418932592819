import React, { useCallback } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import PATHS from 'src/utils/paths'
import withLocation from 'src/hoc/withLocation'

import { getIsAdminUser } from 'src/features/auth/duck/selectors'
import { logoutUserRoutine } from 'src/features/auth/duck/actions'
import LogoWide from 'src/assets/images/vectors/panda-logo-dark.svg'
import LogoShrink from 'src/assets/images/vectors/panda-logo-short.svg'

import ThumbsUp from 'src/components/icons/ThumbsUp'
import User from 'src/components/icons/User'
import Filters from 'src/components/icons/Filters'
import PieChart from 'src/components/icons/PieChart'
import Logout from 'src/components/icons/Logout'
import LanguagePicker from 'src/features/locales/LanguagePicker'
import {
  SideNavigation,
  SideNavListItem
} from 'de-suite-me-ui'

export const AuthNavigationWeb = props => {
  const { location: { pathname }, navigate } = props

  //  REDUX
  const dispatch = useDispatch()
  const isAdminUser = useSelector(getIsAdminUser)
  const logoutUser = useCallback(() => dispatch(logoutUserRoutine()), [
    dispatch
  ])
  const { t } = useTranslation()

  const isCurrentPath = path => pathname === path
  const navigateToPath = path => () => navigate(path)

  return (
    <SideNavigationContainer>
      <SideNavigation
        logoShrink={LogoShrink}
        logoWide={LogoWide}
        onLogoClick={navigateToPath(PATHS.workspaceDashboard)}
        bottomElements={
          <>
            <LanguagePickerContainer>
              <LanguagePicker />
            </LanguagePickerContainer>
            <SideNavListItem
              onClick={logoutUser}
              icon={Logout}
              text={t('authNavigation.logout')}
            />
          </>
        }
      >
        <SideNavListItem
          icon={ThumbsUp}
          onClick={navigateToPath(PATHS.workspaceDashboard)}
          isActive={isCurrentPath(PATHS.workspaceDashboard)}
          text={t('authNavigation.dashboard')}
        />
        <SideNavListItem
          visible={isAdminUser}
          onClick={navigateToPath(PATHS.workspaceUsers)}
          isActive={isCurrentPath(PATHS.workspaceUsers)}
          icon={User}
          text={t('authNavigation.users')}
        />
        <SideNavListItem
          visible={isAdminUser}
          onClick={navigateToPath(PATHS.organisationSettings)}
          isActive={isCurrentPath(PATHS.organisationSettings)}
          icon={Filters}
          text={t('authNavigation.settings')}
        />
        <SideNavListItem
          visible={isAdminUser}
          onClick={navigateToPath(PATHS.workspaceReports)}
          isActive={isCurrentPath(PATHS.workspaceReports)}
          icon={PieChart}
          text={t('authNavigation.reports')}
        />
      </SideNavigation>
    </SideNavigationContainer>
  )
}

export default withLocation(AuthNavigationWeb)

const LanguagePickerContainer = styled.div`
  width: ${props => props.theme.dimensions.navigationShrinkDrawerWidth};
  text-align: center;
  padding: 16px 0;
`

const SideNavigationContainer = styled.div`
  .side-navigation__logo-container {
    padding: 0 7px;
  }
`
