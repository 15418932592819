import { pipe, pathOr } from 'ramda'
import { isNotNilOrEmpty } from 'src/utils/ramda'

export const SLACK_ERRORS = [
  'invalid_client_id',
  'bad_client_secret',
  'invalid_code',
  'bad_redirect_uri',
  'oauth_authorization_url_mismatch',
  'code_already_used',
  'invalid_arguments',
  'invalid_arg_name',
  'invalid_charset',
  'invalid_form_data',
  'invalid_post_type',
  'missing_post_type',
  'team_added_to_org',
  'request_timeout',
  'fatal_error'
]

export const hasOrganisationIntegration = integrationName => pipe(
  pathOr({}, ['integrations', integrationName]),
  isNotNilOrEmpty
)

export const getIntegrationChannelName = integrationName => pipe(
  pathOr({}, ['integrations', integrationName])
)
