import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PATHS from 'src/utils/paths'
import { useTranslation } from 'react-i18next'
import withLocation from 'src/hoc/withLocation'
import LogoDark from 'src/assets/images/vectors/panda-logo-dark.svg'

import { logoutUserRoutine } from 'src/features/auth/duck/actions'
import { getAuthUser, getIsAdminUser } from 'src/features/auth/duck/selectors'

import {
  NavigationMobile,
  SideNavListItem
} from 'de-suite-me-ui'
import ThumbsUp from 'src/components/icons/ThumbsUp'
import User from 'src/components/icons/User'
import Filters from 'src/components/icons/Filters'
import PieChart from 'src/components/icons/PieChart'
import Account from 'src/components/icons/Account'
import BubbleUserAvatar from 'src/components/BubbleUserAvatar'
import LanguagePicker from 'src/features/locales/LanguagePicker'
import Logout from 'src/components/icons/Logout'

const AuthNavigationMobile = props => {
  const { location: { pathname }, navigate } = props
  const { t } = useTranslation()
  //  REDUX
  const dispatch = useDispatch()
  const isAdminUser = useSelector(getIsAdminUser)
  const authUser = useSelector(getAuthUser)
  const logoutUser = useCallback(() => dispatch(logoutUserRoutine()), [
    dispatch
  ])

  const isCurrentPath = path => pathname === path
  const navigateToPath = path => () => navigate(path)

  return (
    <NavigationMobile
      logo={LogoDark}
      onLogoClick={navigateToPath(PATHS.workspaceDashboard)}
      rightElement={<BubbleUserAvatar onClick={navigateToPath(PATHS.workspaceMyProfile)} user={authUser} />}
      drawerBottomElements={
        <>
          <LanguagePicker />
          <SideNavListItem
            onClick={logoutUser}
            icon={Logout}
            text={t('authNavigation.logout')}
          />
        </>
      }
    >
      <SideNavListItem
        icon={ThumbsUp}
        onClick={navigateToPath(PATHS.workspaceDashboard)}
        isActive={isCurrentPath(PATHS.workspaceDashboard)}
        text={t('authNavigation.dashboard')}
      />
      <SideNavListItem
        icon={Account}
        onClick={navigateToPath(PATHS.workspaceMyProfile)}
        isActive={isCurrentPath(PATHS.workspaceMyProfile)}
        text={t('authNavigation.profile')}
      />
      <SideNavListItem
        visible={isAdminUser}
        onClick={navigateToPath(PATHS.workspaceUsers)}
        isActive={isCurrentPath(PATHS.workspaceUsers)}
        icon={User}
        text={t('authNavigation.users')}
      />
      <SideNavListItem
        visible={isAdminUser}
        onClick={navigateToPath(PATHS.organisationSettings)}
        isActive={isCurrentPath(PATHS.organisationSettings)}
        icon={Filters}
        text={t('authNavigation.settings')}
      />
      <SideNavListItem
        visible={isAdminUser}
        onClick={navigateToPath(PATHS.workspaceReports)}
        isActive={isCurrentPath(PATHS.workspaceReports)}
        icon={PieChart}
        text={t('authNavigation.reports')}
      />
    </NavigationMobile>
  )
}

export default withLocation(AuthNavigationMobile)
