import React from 'react'
import IntegrationStatusNotification from 'src/features/integrations/IntegrationStatusNotification'

import DeviceDetector from 'src/components/DeviceDetector'
import NonAuthNavigationWeb from 'src/components/Navigation/NonAuthNavigationWeb'
import NonAuthNavigationMobile from 'src/components/Navigation/NonAuthNavigation.mobile'
import AuthNavigationWeb from 'src/components/Navigation/AuthNavigationWeb'
import AuthNavigationMobile from 'src/components/Navigation/AuthNavigation.mobile'

export const NonAuthNavigation = props => {
  return (
    <>
      <DeviceDetector>
        <div className='web'>
          <NonAuthNavigationWeb />
        </div>
        <div className='mobile'>
          <NonAuthNavigationMobile />
        </div>
      </DeviceDetector>
      <IntegrationStatusNotification />
    </>
  )
}

export const AuthNavigation = props => {
  const { open, toggleDrawer } = props

  return (
    <>
      <DeviceDetector>
        <div className='web'>
          <AuthNavigationWeb open={open} toggleDrawer={toggleDrawer} />
        </div>
        <div className='mobile'>
          <AuthNavigationMobile auth />
        </div>
      </DeviceDetector>
      <IntegrationStatusNotification />
    </>
  )
}
