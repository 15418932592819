export const LANGUAGES = {
  en: 'en',
  pl: 'pl'
}

export const MAPPED_LANGUAGE_CODES = {
  en: 'en-GB',
  pl: 'pl-Pl'
}

export const languageOptionLabelWithValue = {
  [LANGUAGES.pl]: {
    value: LANGUAGES.pl,
    label: 'Polski'
  },
  [LANGUAGES.en]: {
    value: LANGUAGES.en,
    label: 'English'
  }
}

export const languageOptions = [
  languageOptionLabelWithValue[LANGUAGES.pl],
  languageOptionLabelWithValue[LANGUAGES.en]
]
