import React, { useState } from 'react'
import { LANGUAGES } from 'src/utils/locales'
import { KEYS } from 'src/utils/localStorage'
import LocalStorageService from 'src/services/LocalStorageService'
import { changeLanguage } from 'src/providers/i18n'
import styled from 'styled-components'

import { Menu, MenuItem } from 'de-suite-me-ui'

import plFlag from 'src/assets/images/vectors/pl-flag.svg'
import enFlag from 'src/assets/images/vectors/gb-flag.svg'

export const LanguagePicker = props => {
  const [isMenuOpen, setMenuVisibility] = useState(null)
  const currentLanguage =
    LocalStorageService.get(KEYS.selectedLanguage) || LANGUAGES.en
  const [selectedLanguage, setSelectedLanguage] = useState(currentLanguage)

  const handleSelect = value => () => {
    setSelectedLanguage(value)
    changeLanguage(value)
    hideMenu()
  }

  const openMenu = () => setMenuVisibility(true)
  const hideMenu = () => setMenuVisibility(false)

  const flagToShow = selectedLanguage === LANGUAGES.en ? enFlag : plFlag

  return (
    <>
      <Menu
        open={isMenuOpen}
        handleClose={hideMenu}
        handleOpen={openMenu}
        trigger={
          <LanguagePickerContainer>
            <img
              className='language-picker__flag-icon'
              src={flagToShow}
              alt='selected-language'
            />
          </LanguagePickerContainer>
        }
      >
        <MenuItem onClick={handleSelect(LANGUAGES.en)}>
          <SelectFlagIcon src={enFlag} alt='language-en' />
        </MenuItem>
        <MenuItem onClick={handleSelect(LANGUAGES.pl)}>
          <SelectFlagIcon src={plFlag} alt='language-pl' />
        </MenuItem>
      </Menu>
    </>
  )
}

export default LanguagePicker

const LanguagePickerContainer = styled.div`
  display: inline-flex;
  align-items: center;
  height: 100%;
  cursor: pointer;

  .language-picker__flag-icon {
    height: 22px;
  }
  
  ${props => props.theme.breakpointsMedia.mobile} {
    height: auto;
  }
`

const SelectFlagIcon = styled.img`
  height: 22px;
`
