import React, { useCallback, useEffect } from 'react'
import withLocation from 'src/hoc/withLocation'
import qs from 'query-string'
import { SLACK_ERRORS } from 'src/utils/integrations'
import { equals, includes, pipe, propOr } from 'ramda'
import { SEVERITY } from 'src/utils/toast'
import { useDispatch } from 'react-redux'
import { showToastRoutine } from 'src/features/toast/duck/actions'

export const IntegrationStatusNotification = props => {
  const {
    location: { search }
  } = props
  //  REDUX
  const dispatch = useDispatch()
  const showToast = useCallback(
    payload => dispatch(showToastRoutine(payload)),
    [dispatch]
  )

  useEffect(() => {
    slackIntegrationStatusNotification()
  }, [])

  const slackIntegrationStatusNotification = () => {
    const query = qs.parse(search)
    const integrationSucceeded = pipe(
      propOr('', 'slack_added'),
      equals('true')
    )(query)
    const integrationFailed = pipe(propOr('', 'error'), errorCode =>
      includes(errorCode, SLACK_ERRORS)
    )(query)

    switch (true) {
      case integrationSucceeded:
        return showToast({
          key: 'toast.slackAddedSuccess',
          severity: SEVERITY.success
        })
      case integrationFailed:
        return showToast({
          key: 'toast.slackAddedFailed',
          severity: SEVERITY.error
        })
      default:
        return null
    }
  }

  return <div />
}

export default withLocation(IntegrationStatusNotification)
