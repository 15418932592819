import React, { useEffect } from 'react'
import scrollTo from 'gatsby-plugin-smoothscroll'
import styled from 'styled-components'
import PATHS, { LANDING_PAGE_SECTIONS } from 'src/utils/paths'
import { useTranslation } from 'react-i18next'
import withLocation from 'src/hoc/withLocation'
import useScrollDetector from 'src/hooks/useScrollDetector'
import { isNotNilOrEmpty } from 'src/utils/ramda'

import { TopNavigation, TopNavListItem } from 'de-suite-me-ui'
import LanguagePicker from 'src/features/locales/LanguagePicker'
// import DemoButton from 'src/features/landingPage/components/DemoButton'
// import SignUpButton from 'src/features/landingPage/components/SignupButton'
import LogoDark from 'src/assets/images/vectors/panda-logo-dark.svg'
import LogoLight from 'src/assets/images/vectors/panda-logo-light.svg'

const NonAuthNavigationWeb = props => {
  const hasScrolled = useScrollDetector(100)
  const {
    location: { pathname, hash },
    navigate
  } = props
  const { t } = useTranslation()

  useEffect(() => {
    if (isNotNilOrEmpty(hash)) {
      setTimeout(() => scrollTo(hash), 500)
    }
  }, [hash])

  const isCurrentPath = path => pathname === path
  const navigateToPath = path => () => navigate(path)
  const isHomepage = pathname === PATHS.homepage
  const primaryState = isHomepage && !hasScrolled

  const scrollToSection = id => () => navigate(`/#${id}`)

  return (
    <StyledTopNavigation primaryState={primaryState}>
      <TopNavigation
        className='top-nav__list-item'
        onLogoClick={navigateToPath(PATHS.homepage)}
        logo={primaryState ? LogoLight : LogoDark}
      >
        <TopNavListItem
          className='top-nav__list-item'
          onClick={scrollToSection(LANDING_PAGE_SECTIONS.howItWorksSection)}
        >
          {t('navigation.howItWorks')}
        </TopNavListItem>
        <TopNavListItem
          className='top-nav__list-item'
          onClick={scrollToSection(LANDING_PAGE_SECTIONS.aboutSection)}
        >
          {t('navigation.about')}
        </TopNavListItem>
        <TopNavListItem
          className='top-nav__list-item'
          onClick={scrollToSection(LANDING_PAGE_SECTIONS.contactSection)}
        >
          {t('navigation.contact')}
        </TopNavListItem>
        <TopNavListItem
          className='top-nav__list-item'
          isActive={isCurrentPath(PATHS.logIn)}
          onClick={navigateToPath(PATHS.logIn)}
        >
          {t('navigation.login')}
        </TopNavListItem>
        {/* <DemoButton className='top-nav__demo-button' /> */}
        {/* <SignUpButton className='top-nav__demo-button' /> */}
        <LanguagePickerContainer>
          <LanguagePicker />
        </LanguagePickerContainer>
      </TopNavigation>
    </StyledTopNavigation>
  )
}

export default withLocation(NonAuthNavigationWeb)

const LanguagePickerContainer = styled.div`
  margin-left: 20px;
`

const StyledTopNavigation = styled.div`
  & > div {
    background-color: ${props =>
      props.primaryState ? 'transparent' : props.theme.palette.common.white};
    box-shadow: ${props =>
      props.primaryState ? 'none' : props.theme.shadows.main};
    transition: ${props =>
      `all ${props.theme.transitions.duration.standard}ms ${props.theme.transitions.easing.easeInOut}`};
  }

  .top-nav__demo-button {
    background-color: ${props =>
      props.primaryState
        ? props.theme.palette.common.white
        : props.theme.palette.common.flamingo};
    color: ${props =>
      props.primaryState
        ? props.theme.palette.common.black
        : props.theme.palette.primary.contrastText};
    transition: ${props =>
      `all ${props.theme.transitions.duration.standard}ms ${props.theme.transitions.easing.easeInOut}`};
  }

  .top-nav__list-item {
    color: ${props =>
      props.primaryState
        ? props.theme.palette.common.white
        : props.theme.palette.common.black};
    transition: ${props =>
      `all ${props.theme.transitions.duration.standard}ms ${props.theme.transitions.easing.easeInOut}`};
  }
`
