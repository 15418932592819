import i18n from 'i18next'
import 'moment/locale/pl'
import 'moment/locale/en-gb'
import detector from 'i18next-browser-languagedetector'
import backend from 'i18next-xhr-backend'
import { initReactI18next } from 'react-i18next'
import enJSON from 'src/locales/en'
import plJSON from 'src/locales/pl'
import { KEYS } from 'src/utils/localStorage'
import LocalStorageService from 'src/services/LocalStorageService'
import { LANGUAGES } from 'src/utils/locales'
import moment from 'moment'

const selectedLanguage =
  LocalStorageService.get(KEYS.selectedLanguage) || LANGUAGES.en

moment.locale(selectedLanguage)

i18n
  .use(detector)
  .use(backend)
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: enJSON },
      pl: { translation: plJSON }
    },
    lng: selectedLanguage,
    fallbackLng: LANGUAGES.en,
    interpolation: {
      escapeValue: false
    }
  })

export const changeLanguage = lng => {
  moment.locale(lng)
  LocalStorageService.set(KEYS.selectedLanguage, lng)
  i18n.changeLanguage(lng)
}

export default i18n
