export const META = {
  landingPage: {
    title: 'PanDa',
    og_title: 'PanDa',
    description: 'SAAS for PanDa',
    og_description: 'SAAS for PanDa',
    og_image: 'https://desmart.com/images/logo-desmart-white.svg',
    og_image_width: '795',
    og_image_height: '817',
    og_url: 'https://desmart.com'
  },
  customAppreciations: {
    title: 'PanDa',
    og_title: 'PanDa',
    description: 'Podziękowania dla zespołu',
    og_description: 'Podziękowania dla zespołu',
    og_image: 'https://desmart.com/images/logo-desmart-white.svg',
    og_image_width: '795',
    og_image_height: '817',
    og_url: 'https://desmart.com'
  },
  notFound: {
    title: 'PanDa - Not Found',
    og_title: 'PanDa - Not Found',
    description: 'SAAS for PanDa',
    og_description: 'SAAS for PanDa',
    og_image: 'https://desmart.com/images/logo-desmart-white.svg',
    og_image_width: '795',
    og_image_height: '817',
    og_url: 'https://desmart.com'
  },
  signUp: {
    title: 'PanDa - Sign up',
    og_title: 'PanDa - Sign up',
    description: 'SAAS for PanDa',
    og_description: 'SAAS for PanDa',
    og_image: 'https://desmart.com/images/logo-desmart-white.svg',
    og_image_width: '795',
    og_image_height: '817',
    og_url: 'https://desmart.com'
  },
  logIn: {
    title: 'PanDa - Log in',
    og_title: 'PanDa - Log in',
    description: 'SAAS for PanDa',
    og_description: 'SAAS for PanDa',
    og_image: 'https://desmart.com/images/logo-desmart-white.svg',
    og_image_width: '795',
    og_image_height: '817',
    og_url: 'https://desmart.com'
  },
  resetPassword: {
    title: 'PanDa - Reset password',
    og_title: 'PanDa - Reset password',
    description: 'SAAS for PanDa',
    og_description: 'SAAS for PanDa',
    og_image: 'https://desmart.com/images/logo-desmart-white.svg',
    og_image_width: '795',
    og_image_height: '817',
    og_url: 'https://desmart.com'
  },
  manageUsers: {
    title: 'PanDa - Manage Users',
    og_title: 'PanDa - Manage Users',
    description: 'SAAS for PanDa',
    og_description: 'SAAS for PanDa',
    og_image: 'https://desmart.com/images/logo-desmart-white.svg',
    og_image_width: '795',
    og_image_height: '817',
    og_url: 'https://desmart.com'
  },
  myAccount: {
    title: 'PanDa - My Account',
    og_title: 'PanDa - My Account',
    description: 'SAAS for PanDa',
    og_description: 'SAAS for PanDa',
    og_image: 'https://desmart.com/images/logo-desmart-white.svg',
    og_image_width: '795',
    og_image_height: '817',
    og_url: 'https://desmart.com'
  },
  workspace: {
    title: 'PanDa - Workspace',
    og_title: 'PanDa - Workspace',
    description: 'SAAS for PanDa',
    og_description: 'SAAS for PanDa',
    og_image: 'https://desmart.com/images/logo-desmart-white.svg',
    og_image_width: '795',
    og_image_height: '817',
    og_url: 'https://desmart.com'
  },
  integrations: {
    title: 'PanDa - Integrations',
    og_title: 'PanDa - Integrations',
    description: 'SAAS for PanDa',
    og_description: 'SAAS for PanDa',
    og_image: 'https://desmart.com/images/logo-desmart-white.svg',
    og_image_width: '795',
    og_image_height: '817',
    og_url: 'https://desmart.com'
  },
  appreciations: {
    title: 'PanDa - Appreciations',
    og_title: 'PanDa - Appreciations',
    description: 'SAAS for PanDa',
    og_description: 'SAAS for PanDa',
    og_image: 'https://desmart.com/images/logo-desmart-white.svg',
    og_image_width: '795',
    og_image_height: '817',
    og_url: 'https://desmart.com'
  },
  reports: {
    title: 'PanDa - Reports',
    og_title: 'PanDa - Reports',
    description: 'SAAS for PanDa',
    og_description: 'SAAS for PanDa',
    og_image: 'https://desmart.com/images/logo-desmart-white.svg',
    og_image_width: '795',
    og_image_height: '817',
    og_url: 'https://desmart.com'
  }
}
